(function () {
  // Get all elements with the class 'heading-two-colors'
  const Styledheadings = document.querySelectorAll(
    '.is-style-heading-two-colors'
  );

  if (!Styledheadings) {
    return;
  }

  // Loop through each heading
  Styledheadings.forEach((headingItem) => {
    // Split the heading text into an array of words
    const words = headingItem.textContent.trim().split(' ');

    // Check if the heading has at least two words
    if (words.length >= 2) {
      // Wrap the first two words in a span with the class 'primary-color'
      const wrappedText = `<span class="primary-color">${words[0]} ${
        words[1]
      }</span> ${words.slice(2).join(' ')}`;
      headingItem.innerHTML = wrappedText;
    } else {
      // Wrap the first word in a span with the class 'primary-color'
      const wrappedText = `<span class="primary-color">${words[0]}</span>`;
      headingItem.innerHTML = wrappedText;
    }
  });
})(jQuery);
