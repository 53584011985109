const bodyContainer = document.querySelector('body');
const navbar = document.querySelector('.navbar');
const sticky = navbar.offsetTop + 50;

function stickyNavbar() {
  if (window.scrollY >= sticky) {
    navbar.classList.add('sticky');
    bodyContainer.classList.add('sticky-navbar');
  } else {
    navbar.classList.remove('sticky');
    bodyContainer.classList.remove('sticky-navbar');
  }
}
window.addEventListener('load', () => {
  stickyNavbar();
});
window.addEventListener('scroll', () => {
  stickyNavbar();
});
